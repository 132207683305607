<template>
  <v-card>
    <v-card-title>
      <v-btn text color="warning" @click="onCancelClicked"> 閉じる </v-btn>
      <v-spacer />
      物件編集
      <v-spacer />

      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommitClicked"> 更新 </v-btn>
    </v-card-title>

    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab>情報</v-tab>
        <v-tab>活動</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <XSheet :loading="initializing">
            <template v-slot="{ loaded }">
              <v-form v-if="loaded">
                <v-card outlined>
                  <v-card-text style="max-height: 800px">
                    <v-row>
                      <v-col cols="8">
                        <v-card outlined>
                          <v-card-title>基本情報</v-card-title>

                          <v-card-text>
                            <validation-provider v-slot="{ errors }" name="見出し" rules="max:100">
                              <v-text-field
                                label="見出し"
                                v-model="editor.title"
                                counter
                                :maxlength="100"
                                :error-messages="errors"
                                outlined
                                dense
                              />
                            </validation-provider>

                            <validation-provider v-slot="{ errors }" name="物件名" rules="max:100">
                              <v-text-field
                                label="物件名"
                                v-model="editor.name"
                                counter
                                :maxlength="100"
                                :error-messages="errors"
                                outlined
                                dense
                              />
                            </validation-provider>
                            <v-divider />
                            <validation-provider v-slot="{ errors }" name="電話番号" rules="max:50">
                              <v-text-field
                                label="電話番号"
                                v-model="editor.tel"
                                counter
                                :maxlength="50"
                                :error-messages="errors"
                                outlined
                                dense
                              />
                            </validation-provider>

                            <v-row>
                              <v-col md="3">
                                <validation-provider v-slot="{ errors }" name="郵便番号" rules="max:7">
                                  <v-text-field
                                    label="郵便番号"
                                    v-model="editor.postalCode"
                                    counter
                                    :maxlength="7"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                  />
                                </validation-provider>
                              </v-col>

                              <v-col md="6">
                                <validation-provider v-slot="{ errors }" name="エリア" rules="max:100">
                                  <v-text-field
                                    label="エリア"
                                    v-model="editor.areaName"
                                    counter
                                    :maxlength="100"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col md="3">
                                <validation-provider v-slot="{ errors }" name="都道府県" rules="max:100">
                                  <v-select
                                    v-model="editor.prefCode"
                                    :items="dataContext.prefs"
                                    label="都道府県"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                  />
                                </validation-provider>
                              </v-col>

                              <v-col md="6">
                                <validation-provider v-slot="{ errors }" name="住所1" rules="max:100">
                                  <v-text-field
                                    label="住所1"
                                    v-model="editor.address1"
                                    counter
                                    :maxlength="100"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                  />
                                </validation-provider>

                                <validation-provider v-slot="{ errors }" name="住所2" rules="max:100">
                                  <v-text-field
                                    label="住所2"
                                    v-model="editor.address2"
                                    counter
                                    :maxlength="100"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>

                            <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                              <v-textarea
                                label="備考"
                                v-model="editor.memo"
                                counter
                                :maxlength="1000"
                                :error-messages="errors"
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col col="4">
                        <v-card outlined>
                          <v-card-title>情報</v-card-title>
                          <v-card-text>
                            <v-row align="center">
                              <v-col>
                                <validation-provider v-slot="{ errors }" name="" rules="">
                                  <v-chip-group
                                    v-model="editor.hotelTypes"
                                    active-class="deep-purple accent-4 white--text"
                                    :error-messages="errors"
                                    column
                                    multiple
                                    dense
                                  >
                                    <v-chip :value="1" small>ホテル</v-chip>
                                    <v-chip :value="1 << 1" small>旅館</v-chip>
                                    <v-chip :value="1 << 2" small>民宿</v-chip>
                                    <v-chip :value="1 << 3" small>ペンション</v-chip>

                                    <v-chip :value="1 << 9" small>ダミー</v-chip>
                                  </v-chip-group>
                                </validation-provider>
                              </v-col>
                              <v-col>
                                <validation-provider v-slot="{ errors }" name="定員" rules="">
                                  <v-text-field
                                    label="定員"
                                    v-model.number="editor.capacity"
                                    counter
                                    :maxlength="100"
                                    :error-messages="errors"
                                    dense
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>
                            <v-row align="center">
                              <v-col>
                                <validation-provider v-slot="{ errors }" name="支店" rules="">
                                  <v-combobox
                                    label="支店"
                                    item-text="name"
                                    item-value="id"
                                    :items="dataContext.shitens"
                                    v-model="editor.branchs"
                                    :error-messages="errors"
                                    multiple
                                    dense
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col>
                                <validation-provider v-slot="{ errors }" name="担当" rules="">
                                  <v-combobox
                                    label="担当"
                                    item-text="name"
                                    item-value="id"
                                    :items="dataContext.staffs"
                                    v-model="editor.employees"
                                    :error-messages="errors"
                                    multiple
                                    dense
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <validation-provider v-slot="{ errors }" name="lat" rules="max:100">
                                  <v-text-field label="lat" v-model="editor.lat" counter :maxlength="100" :error-messages="errors" dense />
                                </validation-provider>
                              </v-col>
                              <v-col>
                                <validation-provider v-slot="{ errors }" name="lng" rules="max:100">
                                  <v-text-field label="lng" v-model="editor.lng" counter :maxlength="100" :error-messages="errors" dense />
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-card-text>

                          <v-card-text>
                            <v-row>
                              <v-col>
                                <validation-provider v-slot="{ errors }" name="楽天ホテルID" rules="max:100">
                                  <v-text-field
                                    label="楽天ホテルID"
                                    v-model="editor.hotelNo"
                                    counter
                                    :maxlength="100"
                                    :error-messages="errors"
                                    dense
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col>
                                <validation-provider v-slot="{ errors }" name="ナレッジID" rules="max:100">
                                  <v-text-field
                                    label="ナレッジID"
                                    v-model="editor.ksNo"
                                    counter
                                    :maxlength="100"
                                    :error-messages="errors"
                                    dense
                                  />
                                </validation-provider>
                                <v-btn
                                  v-if="editor.ksNo"
                                  text
                                  class="text-decoration-underline"
                                  color="indigo"
                                  :href="`https://gridy.jp/ks_customer#/detail?id=${editor.ksNo}`"
                                  target="_blank"
                                  >ナレッジを開く
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-form>
            </template>
          </XSheet>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined>
            <v-card-text>
              <v-tabs v-model="tab_sub">
                <v-tab
                  ><v-icon>{{ Icons.apo }}</v-icon
                  >アポイント</v-tab
                >
                <v-tab
                  ><v-icon>{{ Icons.demo }}</v-icon
                  >デモ</v-tab
                >
                <v-tab
                  ><v-icon>{{ Icons.leasePayment }}</v-icon
                  >リース</v-tab
                >
                <v-tab
                  ><v-icon>{{ Icons.hpCoverage }}</v-icon
                  >取材</v-tab
                >
                <v-tab
                  ><v-icon>{{ Icons.hpCreate }}</v-icon
                  >制作</v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="tab_sub">
                <v-tab-item>
                  <v-card outlined>
                    <v-card-text>
                      <v-data-table :headers="apo.headers" :items="apo.datas" class="elevation-1" :items-per-page="-1">
                        <template v-slot:item.code="{ item }">
                          {{ item.code | lessStr(5, '..') }}
                        </template>
                        <template v-slot:item.date="{ item }">
                          {{ item.date | dateTime }}
                        </template>
                        <template v-slot:item.demoSchedule="{ item }">
                          {{ item.demoSchedule | dateTime }}
                        </template>

                        <!-- <template v-slot:item.action="{ item }">
                    <v-btn
                      @click="
                        apo.code = item.code;
                        apo.dialog = true;
                      "
                      >編集</v-btn
                    >
                  </template> -->
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card outlined>
                    <v-card-text>
                      <v-data-table :headers="demo.headers" :items="demo.datas" class="elevation-1" :items-per-page="-1">
                        <template v-slot:item.apo.code="{ item }">
                          {{ item.apo ? item.apo.code : null | lessStr(5, '..') }}
                        </template>
                        <template v-slot:item.code="{ item }">
                          {{ item.code | lessStr(5, '..') }}
                        </template>

                        <template v-slot:item.date="{ item }">
                          {{ item.date | date }}
                        </template>

                        <template v-slot:item.reportType="{ item }">
                          {{ item.reportType | demoReportType }}
                        </template>

                        <template v-slot:item.resultType="{ item }">
                          {{ item.resultType | demoResultType }}
                        </template>

                        <template v-slot:item.contractType="{ item }">
                          {{ item.contractType | demoContractType }}
                        </template>

                        <template v-slot:item.saleType="{ item }">
                          {{ item.saleType | demoSaleType }}
                        </template>

                        <template v-slot:item.systemTypes="{ item }">
                          {{ item.systemTypes.map((a) => $options.filters.demoSystemType(a.value)).join() }}
                        </template>

                        <template v-slot:item.sales="{ item }">
                          {{ item.sales | priceJP }}
                        </template>

                        <template v-slot:item.initialCost="{ item }">
                          {{ item.initialCost | priceJP }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card outlined>
                    <v-card-text>
                      <v-data-table :headers="leasePayment.headers" :items="leasePayment.datas" class="elevation-1" :items-per-page="-1">
                        <template v-slot:item.demo.code="{ item }">
                          {{ item.demo ? item.demo.code : null | lessStr(5, '..') }}
                        </template>
                        <template v-slot:item.code="{ item }">
                          {{ item.code | lessStr(5, '..') }}
                        </template>

                        <template v-slot:item.date="{ item }">
                          {{ item.date | dateTime }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card outlined>
                    <v-card-text>
                      <v-data-table :headers="hpCoverage.headers" :items="hpCoverage.datas" class="elevation-1" :items-per-page="-1">
                        <template v-slot:item.demo.code="{ item }">
                          {{ item.demo ? item.demo.code : null | lessStr(5, '..') }}
                        </template>
                        <template v-slot:item.code="{ item }">
                          {{ item.code | lessStr(5, '..') }}
                        </template>

                        <template v-slot:item.date="{ item }">
                          {{ item.date | dateTime }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card outlined>
                    <v-card-text>
                      <v-data-table :headers="hpCreate.headers" :items="hpCreate.datas" class="elevation-1" :items-per-page="-1">
                        <template v-slot:item.code="{ item }">
                          {{ item.code | lessStr(5, '..') }}
                        </template>

                        <template v-slot:item.date="{ item }">
                          {{ item.date | dateTime }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <!-- <v-dialog :value="apo.dialog">
      <ApoHoukoku :show="apo.dialog" :apoCode="apo.code" @commit="apo.dialog = false" @cancel="apo.dialog = false" />
    </v-dialog> -->
  </v-card>
</template>

<script>
// import ApoHoukoku from '@/views/forms/apo_houkoku';

export default {
  components: {
    // ApoHoukoku
  },
  computed: {
    form() {
      return {
        loading: () => this.editor.loading,
        disabled: () => this.editor.loading || this.initializing,
        canSubmit: () => !this.editor.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
  },

  props: {
    facilityCode: {
      type: String,
    },

    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    label: {
      type: String,
      default: () => '施設情報編集',
    },
  },
  data: () => ({
    initializing: true,

    tab: 0,
    tab_sub: 0,

    /** */
    editor: {
      loading: false,

      show: false,
      editable: false,
      title: null,
      subtitle: null,
      name: null,
      lat: null,
      lng: null,
      postalCode: null,
      prefCode: null,
      areaName: null,
      address1: null,
      address2: null,
      tel: null,
      faxNo: null,
      email: null,
      url: null,
      capacity: 0,
      memo: null,
      tags: [],
      avatar: { src: null },
      employees: [],
      branchs: [],
      hotelTypes: [],
      infos: [],

      hotelNo: null,
      ksNo: null,

      files: [],
    },

    apo: {
      dialog: false,
      code: null,
      headers: [
        { text: 'コード', value: 'code' },
        { text: 'アポ日', value: 'date' },
        { text: '担当', value: 'apointer.name' },
        { text: 'デモ日程', value: 'demoSchedule' },
        { text: 'デモ対象', value: 'demoTarget' },
        { text: '支店', value: 'branch.name' },
        { text: '操作', value: 'action' },
      ],
      datas: [],
    },

    demo: {
      headers: [
        { text: 'アポ', value: 'apo.code' },
        { text: 'アポ担当', value: 'apo.apointer.name' },
        { text: 'コード', value: 'code' },
        { text: '担当', value: 'demointer.name' },
        { text: 'デモ日', value: 'date' },
        { text: '報告区分', value: 'reportType' },
        { text: 'デモ結果', value: 'resultType' },
        { text: '受注区分', value: 'contractType' },
        { text: 'システム', value: 'systemTypes' },
        { text: '売上区分', value: 'saleType' },
        { text: '受注金額', value: 'sales' },
        { text: '初期費用', value: 'initialCost' },
      ],
      datas: [],
    },

    leasePayment: {
      headers: [
        { text: 'デモ', value: 'demo.code' },
        { text: 'デモ担当', value: 'demo.demointer.name' },
        { text: 'コード', value: 'code' },
        { text: '審査', value: 'state' },
        { text: '審査日', value: 'examinationDate ' },
        { text: '審査結果日', value: 'examinationResultDate ' },

        { text: '状態', value: 'startState' },

        { text: '回収予定日', value: 'collectScheduleDate ' },
        { text: '提出予定日', value: 'submitScheduleDate ' },

        { text: '回収日', value: 'collectDate ' },
        { text: '提出日', value: 'submitDate ' },
        { text: '開始日', value: 'startDate ' },
        { text: '入金予定日', value: 'completeScheduleDate ' },
        { text: '入金日', value: 'completeDate ' },
      ],
      datas: [],
    },

    hpCoverage: {
      headers: [
        { text: 'デモ', value: 'demo.code' },
        { text: 'デモ担当', value: 'demo.demointer.name' },
        { text: 'コード', value: 'code' },
        { text: '状態', value: 'state' },
        { text: '取材予定日', value: 'scheduleDate' },
        { text: '取材日', value: 'date' },
        { text: '導入担当', value: 'setuper.name' },
        { text: '取材担当', value: 'coverager.name' },
        { text: '構成担当', value: 'hpCreateSubmiter.name' },
        { text: 'リース開始', value: 'leasePaymentStartState' },
      ],
      datas: [],
    },

    hpCreate: {
      headers: [
        { text: 'コード', value: 'code' },
        { text: '状態', value: 'state' },
      ],
      datas: [],
    },
  }),
  methods: {
    onLoaded() {
      this.initializing = true;
      //
      this.get('facility', { facilityCode: this.facilityCode }).then((success) => {
        let data = success.data;
        //
        this.editor.code = data.code;
        this.editor.title = data.title;
        this.editor.subtitle = data.subtitle;
        this.editor.name = data.name;
        this.editor.lat = data.lat;
        this.editor.lng = data.lng;
        this.editor.postalCode = data.postalCode;
        this.editor.prefCode = data.prefCode;

        this.editor.areaName = data.areaName;
        this.editor.address1 = data.address1;
        this.editor.address2 = data.address2;
        this.editor.tel = data.tel;
        this.editor.faxNo = data.faxNo;
        this.editor.email = data.email;
        this.editor.url = data.url;
        this.editor.capacity = data.capacity;
        this.editor.memo = data.memo;
        this.editor.tags = data.tags;
        this.editor.avatar = data.avatar;
        this.editor.employees = data.employees.map((a) => {
          return { id: a.code, name: a.name, value: '' };
        });
        this.editor.branchs = data.branchs.map((a) => {
          return { id: a.code, name: a.name, value: '' };
        });
        this.editor.hotelTypes = data.hotelTypes.map((a) => a.value);
        this.editor.infos = data.infos;

        this.editor.hotelNo = data.hotelNo;
        this.editor.ksNo = data.ksNo;

        //
        this.apo.datas = data.apos;
        this.demo.datas = data.demos;
        this.leasePayment.datas = data.leasePayments;
        this.hpCoverage.datas = data.hpCoverages;
        this.hpCreate.datas = data.hpCreates;

        this.initializing = false;
      });
    },

    onCancelClicked() {
      //
      this.$emit('cancel');
    },

    /** */
    async onCommitClicked() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      let request = {
        facilityCode: this.editor.code,
        title: this.editor.title,
        subtitle: this.editor.subtitle,
        name: this.editor.name,
        lat: this.editor.lat,
        lng: this.editor.lng,
        postalCode: this.editor.postalCode,
        prefCode: this.editor.prefCode,
        areaName: this.editor.areaName,
        address1: this.editor.address1,
        address2: this.editor.address2,
        tel: this.editor.tel,
        faxNo: this.editor.faxNo,
        email: this.editor.email,
        url: this.editor.url,
        capacity: this.editor.capacity,
        memo: this.editor.memo,
        tags: this.editor.tags,
        employees: this.editor.employees.map((a) => a.id),
        branchs: this.editor.branchs.map((a) => a.id),
        hotelTypes: this.editor.hotelTypes,
        hotelNo: this.editor.hotelNo,
        ksNo: this.editor.ksNo,
      };
      let file = this.editor.avatar.file;

      // nullable
      request.capacity = this.convertNumber(request.capacity);

      this.putUpload('facility', request, [file])
        .then(() => {
          //
          this.showSuccess('施設情報を更新しました');
          //
          this.$emit('commit', this.editor);
        })
        .catch(() => {
          this.showError('施設情報更新中にエラーが発生しました');
        });

      // this.makerEditor.show = true;
      // this.makerEditor.code = marker.code;
      // this.makerEditor.lat = marker.lat;
      // this.makerEditor.lng = marker.lng;
      // this.makerEditor.color = marker.color;
      // this.makerEditor.message = marker.message;
      // this.makerEditor.witem = marker;
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
};
</script>