var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":_vm.onCancelClicked}},[_vm._v(" 閉じる ")]),_c('v-spacer'),_vm._v(" 物件編集 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommitClicked}},[_vm._v(" 更新 ")])],1),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("情報")]),_c('v-tab',[_vm._v("活動")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('v-form',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticStyle:{"max-height":"800px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("基本情報")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"見出し","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"見出し","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.editor.title),callback:function ($$v) {_vm.$set(_vm.editor, "title", $$v)},expression:"editor.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"物件名","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"物件名","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.editor.name),callback:function ($$v) {_vm.$set(_vm.editor, "name", $$v)},expression:"editor.name"}})]}}],null,true)}),_c('v-divider'),_c('validation-provider',{attrs:{"name":"電話番号","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"電話番号","counter":"","maxlength":50,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.editor.tel),callback:function ($$v) {_vm.$set(_vm.editor, "tel", $$v)},expression:"editor.tel"}})]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"郵便番号","rules":"max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"郵便番号","counter":"","maxlength":7,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.editor.postalCode),callback:function ($$v) {_vm.$set(_vm.editor, "postalCode", $$v)},expression:"editor.postalCode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"エリア","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"エリア","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.editor.areaName),callback:function ($$v) {_vm.$set(_vm.editor, "areaName", $$v)},expression:"editor.areaName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"都道府県","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.prefs,"label":"都道府県","item-text":"name","item-value":"id","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.editor.prefCode),callback:function ($$v) {_vm.$set(_vm.editor, "prefCode", $$v)},expression:"editor.prefCode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"住所1","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"住所1","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.editor.address1),callback:function ($$v) {_vm.$set(_vm.editor, "address1", $$v)},expression:"editor.address1"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"住所2","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"住所2","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.editor.address2),callback:function ($$v) {_vm.$set(_vm.editor, "address2", $$v)},expression:"editor.address2"}})]}}],null,true)})],1)],1),_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","counter":"","maxlength":1000,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.editor.memo),callback:function ($$v) {_vm.$set(_vm.editor, "memo", $$v)},expression:"editor.memo"}})]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"col":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("情報")]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-chip-group',{attrs:{"active-class":"deep-purple accent-4 white--text","error-messages":errors,"column":"","multiple":"","dense":""},model:{value:(_vm.editor.hotelTypes),callback:function ($$v) {_vm.$set(_vm.editor, "hotelTypes", $$v)},expression:"editor.hotelTypes"}},[_c('v-chip',{attrs:{"value":1,"small":""}},[_vm._v("ホテル")]),_c('v-chip',{attrs:{"value":1 << 1,"small":""}},[_vm._v("旅館")]),_c('v-chip',{attrs:{"value":1 << 2,"small":""}},[_vm._v("民宿")]),_c('v-chip',{attrs:{"value":1 << 3,"small":""}},[_vm._v("ペンション")]),_c('v-chip',{attrs:{"value":1 << 9,"small":""}},[_vm._v("ダミー")])],1)]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"定員","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"定員","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.editor.capacity),callback:function ($$v) {_vm.$set(_vm.editor, "capacity", _vm._n($$v))},expression:"editor.capacity"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"支店","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":"支店","item-text":"name","item-value":"id","items":_vm.dataContext.shitens,"error-messages":errors,"multiple":"","dense":""},model:{value:(_vm.editor.branchs),callback:function ($$v) {_vm.$set(_vm.editor, "branchs", $$v)},expression:"editor.branchs"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"担当","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":"担当","item-text":"name","item-value":"id","items":_vm.dataContext.staffs,"error-messages":errors,"multiple":"","dense":""},model:{value:(_vm.editor.employees),callback:function ($$v) {_vm.$set(_vm.editor, "employees", $$v)},expression:"editor.employees"}})]}}],null,true)})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"lat","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"lat","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.editor.lat),callback:function ($$v) {_vm.$set(_vm.editor, "lat", $$v)},expression:"editor.lat"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"lng","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"lng","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.editor.lng),callback:function ($$v) {_vm.$set(_vm.editor, "lng", $$v)},expression:"editor.lng"}})]}}],null,true)})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"楽天ホテルID","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"楽天ホテルID","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.editor.hotelNo),callback:function ($$v) {_vm.$set(_vm.editor, "hotelNo", $$v)},expression:"editor.hotelNo"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"ナレッジID","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ナレッジID","counter":"","maxlength":100,"error-messages":errors,"dense":""},model:{value:(_vm.editor.ksNo),callback:function ($$v) {_vm.$set(_vm.editor, "ksNo", $$v)},expression:"editor.ksNo"}})]}}],null,true)}),(_vm.editor.ksNo)?_c('v-btn',{staticClass:"text-decoration-underline",attrs:{"text":"","color":"indigo","href":("https://gridy.jp/ks_customer#/detail?id=" + (_vm.editor.ksNo)),"target":"_blank"}},[_vm._v("ナレッジを開く ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()]}}])})],1),_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab_sub),callback:function ($$v) {_vm.tab_sub=$$v},expression:"tab_sub"}},[_c('v-tab',[_c('v-icon',[_vm._v(_vm._s(_vm.Icons.apo))]),_vm._v("アポイント")],1),_c('v-tab',[_c('v-icon',[_vm._v(_vm._s(_vm.Icons.demo))]),_vm._v("デモ")],1),_c('v-tab',[_c('v-icon',[_vm._v(_vm._s(_vm.Icons.leasePayment))]),_vm._v("リース")],1),_c('v-tab',[_c('v-icon',[_vm._v(_vm._s(_vm.Icons.hpCoverage))]),_vm._v("取材")],1),_c('v-tab',[_c('v-icon',[_vm._v(_vm._s(_vm.Icons.hpCreate))]),_vm._v("制作")],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab_sub),callback:function ($$v) {_vm.tab_sub=$$v},expression:"tab_sub"}},[_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.apo.headers,"items":_vm.apo.datas,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("lessStr")(item.code,5, '..'))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.date))+" ")]}},{key:"item.demoSchedule",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.demoSchedule))+" ")]}}])})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.demo.headers,"items":_vm.demo.datas,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.apo.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("lessStr")(item.apo ? item.apo.code : null,5, '..'))+" ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("lessStr")(item.code,5, '..'))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.reportType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("demoReportType")(item.reportType))+" ")]}},{key:"item.resultType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("demoResultType")(item.resultType))+" ")]}},{key:"item.contractType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("demoContractType")(item.contractType))+" ")]}},{key:"item.saleType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("demoSaleType")(item.saleType))+" ")]}},{key:"item.systemTypes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.systemTypes.map(function (a) { return _vm.$options.filters.demoSystemType(a.value); }).join())+" ")]}},{key:"item.sales",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceJP")(item.sales))+" ")]}},{key:"item.initialCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceJP")(item.initialCost))+" ")]}}])})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.leasePayment.headers,"items":_vm.leasePayment.datas,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.demo.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("lessStr")(item.demo ? item.demo.code : null,5, '..'))+" ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("lessStr")(item.code,5, '..'))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.date))+" ")]}}])})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.hpCoverage.headers,"items":_vm.hpCoverage.datas,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.demo.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("lessStr")(item.demo ? item.demo.code : null,5, '..'))+" ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("lessStr")(item.code,5, '..'))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.date))+" ")]}}])})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.hpCreate.headers,"items":_vm.hpCreate.datas,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("lessStr")(item.code,5, '..'))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.date))+" ")]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }